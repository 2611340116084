<template>
  <div class="container">
    <div class="top">
      <a href="javascript:;" @click="showSort = !showSort" :class="showSort ? 'change' : ''">{{checkedSort.name}}<img src="../assets/img/icon-dropdown.png" alt="" /></a>
    </div>
    <div class="typechange" v-show="showSort">
      <div class="list">
        <a href="javascript:;" v-for="(item,index) in sortList" :key="index" :class="checkedSort.code == item.code ? 'active' : ''" @click="changeSort(item)">{{item.name}}</a>
      </div>
    </div>
    <!--订单列表-->
    <div class="content">
      <div class="filter">
        <ul>
          <a href="javascript:;" v-for="(item,index) in filter" :key="index" :class="checkedFilter.code == item.code ? 'active' : ''" @click="checkType(item)">{{item.name}}<i></i></a>
        </ul>
      </div>
      <div class="list" :class="checkedSort.code == 1 ? 'hotel' : ''">
        <div class="orderbox">
          <div class="title"><span>已取消</span><h1><img src="../assets/img/icon-ticketOrder.png" />峨眉山门票(成人)</h1></div>
          <div class="info">
            <img src="../assets/img/mp.png" />
            <div class="detailinfo" v-if="checkedSort.code == 0">
              <p><span>2021-01-20至2021-01-22有效</span>共1张</p>
              <p>总价：<em>￥110.00</em></p>
            </div>
            <div class="detailinfo" v-else>
              <h2>峨眉山温泉饭店</h2>
              <p>标准房-不含温泉|2份早餐|双床|2人入驻|有窗</p>
              <p>入驻时间：1月20日 - 1月21日</p>
              <p><span>共计2间</span>合计<em>￥260.00</em></p>
            </div>
          </div>
          <div class="btn">
            <a href="javascript:;">再次购买</a>
            <a href="javascript:;" class="green">立即付款</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Order',
    data () {
      return{
        // 酒店或票务选择
        sortList: [
          {name: '峨眉山景区票务', code: 0},
          {name: '智游乐山酒店', code: 1}
        ],
        checkedSort: {}, // 选择的订单类型
        showSort: false, // 显示酒店或票务选择
        // 票务订单类型列表
        pwFilterList: [
          {name: '全部', code: 0},
          {name: '待付款', code: 1},
          {name: '待使用', code: 2},
          {name: '待评价', code: 3},
          {name: '退款', code: 4}
        ],
        // 酒店订单类型列表
        jdFilterList: [
          {name: '全部', code: 0},
          {name: '待付款', code: 1},
          {name: '待使用', code: 2},
          {name: '待评价', code: 3},
          {name: '退款', code: 4}
        ],
        filter: [], // 订单状态筛选列表
        checkedFilter: {}, // 选择的订单状态
        list: [], // 列表
      }
    },
    mounted() {
      console.log('我的订单')
      this.checkedSort = this.sortList[0] // 选择的订单类型
      this.filter = this.pwFilterList // 默认票务订单类型筛选
      this.checkedFilter = this.filter[0] // 默认全部订单
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    methods: {
      // 选择酒店或票务订单
      changeSort(sort) {
        if (this.checkedSort != sort) {
          this.checkedSort = sort
          if (sort.code == 0) {
            this.filter = this.pwFilterList
          } else {
            this.filter = this.jdFilterList
          }
          this.checkedFilter = this.filter[0] // 默认全部订单
        }
        this.showSort = false
      },
      // 票务订单类型筛选
      checkType(type) {
        if(this.checkedFilter != type){
          this.checkedFilter = type
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: #F7F7F7;
    box-sizing: border-box;
    .top{
      position: fixed;
      width: 100%;
      height: 50px;
      background: linear-gradient(to right, #7ddb4f, #21dd53);
      text-align: center;
      a{
        font-size: 16px;
        line-height: 50px;
        color: #fff;
        img{
          vertical-align: middle;
          height: 6px;
          margin: -3px 0 0 6px;
          transition: all .25s linear;
        }
      }
      a.change{
        img {
          transform: rotateZ(180deg);
        }
      }
    }
    .typechange{
      position: fixed;
      top: 50px;
      width: 100%;
      height: calc(100vh - 50px);
      background: rgba(0, 0, 0, .75);
      z-index: 10;
      .list{
        background: #fff;
        a{
          display: block;
          font-size: 15px;
          color: #333;
          text-align: left;
          box-sizing: border-box;
          padding: 0 20px;
          line-height: 50px;
          border-bottom: 1px solid #f0f0f0;
        }
        a.active{
          color: #21dd53;
          background: url(../assets/img/icon-selected.png) no-repeat 96% center/auto 40%;
        }
      }
    }
    .content{
      padding-top: 100px;
      .filter{
        background: #fff;
        position: fixed;
        width: 100%;
        top: 50px;
        height: 50px;
        line-height: 50px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        z-index: 1;
        font-size: 0;
        ul {
          height: 60px;
          padding: 0 20px;
          box-sizing: border-box;
          overflow: hidden;
          overflow-x: auto;
        }
        a {
          font-size: 15px;
          color: #444;
          margin-left: 20px;
          display: inline-block;
          position: relative;
          transition: all 0.25s linear;
          i {
            position: absolute;
            width: 0;
            left: 50%;
            bottom: 8px;
            height: 4px;
            background: linear-gradient(to right, #7ddb4f, #21dd53);
            transition: all 0.25s linear;
          }
        }
        a.active{
          color: #000;
          i {
            width: 100%;
            left: 0;
          }
        }
        a:first-of-type {
          margin-left: 0;
        }
      }
      .list {
        min-height: calc(100vh - 100px);
        padding: 0 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        .orderbox{
          width: 100%;
          display: inline-block;
          margin-top: 10px;
          background: #fff;
          border-radius: 5px;
          .title{
            font-size: 14px;
            white-space: nowrap;
            padding: 0 10px;
            box-sizing: border-box;
            line-height: 40px;
            border-bottom: 1px solid #f0f0f0;
            h1 {
              font-size: 14px;
              color: #222;
              font-weight: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              img{
                height: 20px;
                margin-right: 5px;
                vertical-align: text-top;
              }
            }
            span{
              float: right;
            }
          }
          .info{
            padding: 10px;
            box-sizing: border-box;
            img {
              width: 60px;
              height: 60px;
              vertical-align: top;
              border-radius: 5px;
            }
            .detailinfo{
              width: calc(100% - 70px);
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;
              p {
                font-size: 12px;
                padding-bottom: 8px;
                span{
                   float: right;
                }
                em {
                  color: #cc0000;
                  font-style: normal;
                  font-size: 16px;
                }
              }
            }
          }
          .btn {
            padding: 5px 10px;
            border-top: 1px solid #f0f0f0;
            text-align: right;
            a {
              border: 1px solid #e1e1e1;
              border-radius: 20px;
              line-height: 28px;
              padding: 0 15px;
              font-size: 14px;
              color: #222;
              display: inline-block;
              margin-left: 10px;
              vertical-align: middle;
            }
            a:first-of-type {
              margin-left: 0;
            }
            a.green{
              color: #fff;
              background: linear-gradient(to right, #7ddb4f, #21dd53);
              border-image: linear-gradient(to right, #7ddb4f, #21dd53);
            }
          }
        }
      }
      .hotel{
        .orderbox{
          .info {
            img{
              width: 100px;
              height: 100px;
            }
            .detailinfo{
              width: calc(100% - 110px);
              h2{
                font-size: 16px;
                padding-bottom: 8px;
              }
              p{
                span{
                  float: none;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
